import React from "react";
import { css } from "@emotion/core";

import MainLayout from "../components/main-layout";
import { BlurbText, WhiteLinkText } from "../components/styled-components";

const FourOhFour = () => {
  return (
    <>
      <MainLayout>
        <div
          css={css`
            width: 100%;
            height: 60vh;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <BlurbText size="1.5rem">
            We are sorry, it looks like something may have went wrong!
          </BlurbText>
          <br />
          <div
            css={css`
              width: 100px;
              height: 50px;
              padding: 10px;
              background-color: #46c39f;
              text-align: center;
              z-index: 150;
            `}
          >
            <WhiteLinkText to="/">HOME</WhiteLinkText>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default FourOhFour;
